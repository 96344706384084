<template>
  <div>
    <div class="filters row" style="margin-top:0">
      <div class="col-1-3">        
        <select
            class="form-control"
            v-model="tipoTicketSelect"
            v-on:change="debounceLoadTipoTicketEtapa()"
          >
            <option value="" selected>Todos os tipos</option>
            <option v-for="i in tipoTicket" v-bind:value="i.id" v-bind:key="i.id">
              {{ i.desc }}
            </option>
          </select>        
      </div>
      <div class="col-2-3">
        <div class="pull-right">
          <button v-on:click="openCreate('tipoTicketEtapaEdit')" class="button button-success button-block">
            <fa-icon icon="plus" />
            Criar
          </button>
        </div>
      </div>
    </div>
    <div class="table-responsive">
      <table class="table table-hover">
        <thead>
          <tr>
            <th>#</th>
            <th>Descrição</th>
            <th>Tipo Ticket</th>
            <th>SLA</th>
            <th></th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="item in items">
            <td class="highlight">
              {{ item.id }}
            </td>
            <td>{{ item.desc }}</td>
            <td>
              <span class="label primary">{{ getDescricaoTipoTicket(item.tipo_ticket_id) }}</span>
            </td>
            <td>{{ item.sla_maximo }}</td>
            <td class="actions">
              <button type="button" class="button button-primary" v-on:click="openEdit(item, 'tipoTicketEtapaEdit')"><fa-icon icon="pencil-alt" /></button>
              <button type="button" name="button" v-on:click="deleteConfirmTipoTicketEtapa(item.id)" class="button button-error"><fa-icon icon="trash-alt" /></button>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</template>

<script>
export default {
  name: 'list',
  props: ['items' , 'openEdit', 'openCreate', 'deleteConfirmTipoTicketEtapa', 'tipoTicket', 'loadTipoTicketEtapa'],
  data() {
    return {
      tipoTicketSelect: '',
    };
  },
  methods: {
    getDescricaoTipoTicket(idTipoTicket) {
      if (!idTipoTicket || !this.tipoTicket) {
        return undefined;
      }
      
      const { desc } = this.tipoTicket.find(
        (m) => m.id === idTipoTicket
      );

      return desc;
    },
    debounceLoadTipoTicketEtapa() {
      this.loadTipoTicketEtapa(this.tipoTicketSelect);
    }
  }
}
</script>
